import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ThemeProvider as ThemeProviderSC } from "@emotion/react";

import { theme } from "./Theme";

import App from "./App";

import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProviderSC theme={theme}>
      <ThemeProvider theme={theme}>
        <Router basename={"/"}>
          <App />
        </Router>
      </ThemeProvider>
    </ThemeProviderSC>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
