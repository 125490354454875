import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Button as ButtonMui, useTheme } from "@mui/material";

function Button(props) {
  const theme = useTheme();
  return (
    <ButtonWrapper
      {...props}
      target={props.target || "_self"}
      href={props.href}
      variant="contained"
    >
      {props.children}
    </ButtonWrapper>
  );
}
const setActive = props => {
  if (props.active === true) {
    return props.theme.palette.secondary.dark;
  }
  return null;
};
const ButtonWrapper = styled(ButtonMui)`
  // margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  width: 200px;
  height: 65px;
  text-transform: unset;
  border-radius: 200px;
  // border: 2px solid ${props => props.theme.palette.primary.main};
  background-color: ${props => setActive(props)};
  color: ${props => props.theme.palette.common.white};
  font-family: verdana, sans-serif;

  font-style: italic;
  letter-spacing: 0.52px;
  font-size: 0.8rem;
  line-height: 1rem;
  ${props => props.theme.breakpoints.up("sm")} {
    // margin-top: 2.5rem;
  }
  &:hover {
    background-color: ${props => props.theme.palette.secondary.dark};
    // color: #fff;
  }
  &:active {
    // color: #fff;
    // background-color: ${props => props.theme.palette.primary.main};
  }
  &.selected {
    // color: #fff;
    // background-color: ${props => props.theme.palette.primary.main};
  }
`;
Button.propTypes = {
  target: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
