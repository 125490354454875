import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import {
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  Link as MUILink,
  useScrollTrigger,
  Slide,
  Drawer,
  Typography,
} from "@mui/material";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";
import { Event } from "./Tracking";
import MenuIcon from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";

import { motion, useViewportScroll } from "framer-motion";
import NavItem from "./NavItem";
import LogoSVG from "../assets/navLogo.svg";
import LogoReversed from "../assets/navLogo.svg";
// import { Link } from "react-router-dom";
//TODO consolidate calendly stuff to one location. Currently multiple implementations
// const options = {
//   url: process.env.REACT_APP_CALENDLY_LINK,
// };

// const handleBookATourClick = event => {
//   Event("click", "Book a Tour", "small screen");
// };
// const handleRentCafeClick = event => {
//   Event("click", "Rent Cafe", "small screen");
// };

export default function MobileMenu({ ...props }) {
  const theme = useTheme();

  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
    return () => true;
  }, [scrollYProgress]);

  const [open, setOpen] = React.useState(false);
  const handleToggle = event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(!open);
  };

  const createEvent = event => {
    Event("Nav", "click", event.currentTarget.text);
  };

  const handleOnClick = event => {
    createEvent(event);
    props.scrollToTop();

    return true;
  };

  return (
    // <HideOnScroll>
    <AppBar position="fixed">
      <StyledToolbar>
        <NavItem href="/" handleOnClick={handleOnClick}>
          <Logo showmobile={true} alt="Blume Apartments Logo" role="presentation" src={LogoSVG} />
        </NavItem>
        {/* <StyledBlock showmobile={true}>Avro Business Park</StyledBlock> */}
        <StyledIconButton
          edge="start"
          // color="inherit"
          aria-label="open drawer"
          // open={handleToggle}
          onClick={handleToggle}
        >
          <StyledMenuIconButton />
        </StyledIconButton>

        {/* <StyledBlock hidemobile={false}></StyledBlock> */}
      </StyledToolbar>
      {/* TODO close drawer when window is resized */}
      <Drawer
        open={open}
        // containerStyle={styles.navBar}
        PaperProps={{ component: StyledPaper }}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="presentation"
        anchor="right"
        id="Drawer"
      >
        <Close style={{ alignSelf: "flex-end" }} />

        <DrawerNavContainer id="drawer-nav-container">
          <NavItem href="/" onClick={props.handleNavClick} style={{ marginBottom: "2rem" }}>
            <DrawerLogo src={LogoReversed}></DrawerLogo>
          </NavItem>

          {/* {props.data.map((item, index, items) => {
            return (
              <NavItem
                key={item.link}
                href={item.link}
                handleOnClick={handleOnClick}
                
              >
                {item.Label}
              </NavItem>
            );
          })} */}

          <NavItem href="/amenities" handleOnClick={handleOnClick}>
            Amenities
          </NavItem>

          <NavItem href="/floorplans" handleOnClick={handleOnClick}>
            Floorplans
          </NavItem>

          <NavItem href="/community" handleOnClick={handleOnClick}>
            Community
          </NavItem>

          <NavItem href="/contact" handleOnClick={handleOnClick}>
            Contact
          </NavItem>

          {/* <NavItem href="/connect" handleOnClick={handleOnClick} data-copy="">
            Connect
        </NavItem>*/}
          <Button
            to="/#contact"
            color="primary"
            onClick={props.handleBookATourClick}
            alt="Contact Us"
            aria-controls="navigation"
            tabIndex={0}
          >
            Book a Tour
          </Button>
          {/* <NavItem href="/disclaimer" handleOnClick={handleOnClick} style={{ marginTop: "2rem" }}>
            <Typography
              variant="caption"
              color="initial"
              sx={{ fontFamily: "'Poppins', sans-serif" }}
            >
              Disclaimer
            </Typography>
          </NavItem> */}
        </DrawerNavContainer>
      </Drawer>
    </AppBar>
    // </HideOnScroll>
  );
}
const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 1.8rem;
  background-color: transparent;
`;

const StyledMenuIconButton = styled(MenuIcon)`
  background-color: transparent;
  width: 1.3em;
  height: 1.3em;
  color: ${props => props.theme.palette.primary.dark};
`;
const NavItemText = styled(HashLink)`
  text-transform: uppercase;
`;
const StyledPaper = styled("div")`
  overflow: hidden;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 26%),
    center 100% / 113% auto no-repeat url();
  padding-top: 2em;
  padding-right: 2em;
  width: 300px;
  @media (min-width: 480px) {
    width: 300px;
  }
`;
const DrawerNavContainer = styled("nav")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 0 0 3em 3em;
  flex: 1;
  @media (min-width: 480px) {
    width: 100%;
  }
  /* max-height: 500px; */
  a.active {
    background-color: transparent;
    text-decoration: none;
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }

  .MuiButton-contained {
    margin-top: 1rem;
    background-color: ${props => props.theme.palette.primary.dark};

    color: ${props => props.theme.palette.common.white};
    &:hover {
      background-color: ${props => props.theme.palette.secondary.dark};
    }
  }
  .MuiButton-label {
    font-size: 1rem;
    font-style: normal;
    color: ${props => props.theme.palette.common.white};
  }
  a {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    margin: 0.5em;
    color: ${props => props.theme.palette.primary.main};
    font-family: "Poppins", sans-serif;
    &.active {
      color: ${props => props.theme.palette.secondary.dark};
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      &:before {
        display: inline-block;
        position: absolute;
        left: 35px;
        color: ${props => props.theme.palette.secondary.dark};
        content: ">";
      }
    }
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }
`;
// const StyledAppBar = styled(AppBar)``;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  height: 58px;

  /* max-height: 55px; */
  /* margin-bottom: 25px; */

  z-index: 500;
  @media (min-width: 480px) {
    background-color: ${props => props.theme.darkBlue};
    height: 66px;
    /* max-height: 100px; */
  }
`;

const DrawerLogo = styled("img")`
  /* max-height: 15; */
  // width: 5rem;
  // height: auto;
  /* margin-bottom: 2em; */
  width: 100px;
  height: auto;
`;
const Logo = styled("img")`
  width: auto;
  height: 2.5rem;
  margin-top: 0.3em;

  // @media (min-width: 480px) {
  //   margin-top: 0.3em;
  //   height: 2.5rem;
  // }
`;
