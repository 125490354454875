import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material";

//clearwater

const titleFont = "'Verdana', sans-serif";
const font = "'Verdana',sans-serif";

const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    maxWidth: "1920px",
    activeColor: "#6098AF",
    palette: {
      primary: {
        main: "rgba(9, 39, 75, 1)",
        dark: "rgba(4, 19, 37, 1)",
        light: "rgba(244, 244, 255, 1)",
        lightlight: "rgb(156,215,250,1)",
      },
      secondary: {
        main: "rgb(100,205,170,1)",
        dark: "rgb(0,131,126,1)",
        light: "rgb(207,250,231,1)",
      },

      action: {
        //This is a reference. You can use theme.palette.action.active etc.
        // active: "rgba(192, 202, 200, 0.54)",
        // hover: "rgba(37,95,126,1)",
        // hoverOpacity: 1,
        // selected: "rgba(192, 202, 200, 0.54)",
        // selectedOpacity: 0.08,
        // disabled: "rgba(0, 0, 0, 0.26)",
        // disabledBackground: "rgba(0, 0, 0, 0.12)",
        // disabledOpacity: 0.38,
        // focus: "rgba(0, 0, 0, 0.12)",
        // focusOpacity: 0.12,
        // activatedOpacity: 0.12,
      },
      text: {
        primary: "rgba(9,39,75, 1)",
        secondary: "rgba(207,250,231,1)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    typography: {
      htmlFontSize: 18,

      // fontSize: 12,
    },
  })
);

export const theme = createMuiTheme({
  ...defaultTheme,
  overrides: {
    // MuiButton: {
    //   root: {
    //     borderRadius: "50%",
    //   },
    // },
    // Style sheet name
    // MuiIconButton: {
    //   //this will change the background of the button
    //   colorPrimary: {
    //     "&:hover": {
    //       backgroundColor: defaultTheme.palette.primary.hover,
    //       color: defaultTheme.palette.common.white,
    //     },
    //   },
    // },
  },
  typography: {
    h4: {
      fontSize: 28,
      fontFamily: titleFont,
      fontWeight: 400,
      letterSpacing: 2,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 22,
      },
    },
    h6: {
      fontSize: 15,
      fontFamily: titleFont,
      fontWeight: 400,
      letterSpacing: 2,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 15,
      },
    },
    h1: {
      fontSize: "1.9rem",
      fontFamily: "Poppins",
      letterSpacing: "4.8px",
      textTransform: "uppercase",
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "3.33rem",
      },
    },
    h3: {
      fontFamily: font,
      fontSize: "1.375rem",
      letterSpacing: "1.76px",
      textTransform: "uppercase",
      color: "#16284c",
      fontWeight: 400,
      // [defaultTheme.breakpoints.up("md")]: {
      //   fontSize: "3.33rem",
      // },
    },

    body1: {
      maxWidth: defaultTheme.breakpoints.values.lg,
      lineHeight: 2.125,
      fontWeight: 400,
      fontSize: 16,
      fontFamily: font,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: 13,
        lineHeight: 2.025,
      },
    },
    aside: {
      fontSize: ".8rem",
      color: defaultTheme.palette.grey[500],
      fontFamily: font,
    },
  },
});
