import axios from "axios";
import { getLocalStorage } from "./getLocalStorage";
const qs = require("qs");
export async function getAmenity(setter, key) {
  const query = qs.stringify(
    {
      populate: "deep",
    },
    // {
    //   populate: {
    //     testDZ: {
    //       populate: "*",
    //     },
    //   },
    // },
    {
      encodeValuesOnly: true,
    }
  );
  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };
  axios
    .get(`${process.env.REACT_APP_CMS_URL}/api/amenitie/?${query}`, config)
    .then(res => {
      const dataTest = JSON.stringify(res.data.data.attributes);

      const modifiedData = JSON.parse(
        dataTest.replace(/\/uploads\/+/g, `${process.env.REACT_APP_CMS_URL}/uploads/`)
      );
      //update as needed
      // const cleanData = res.data.data.map((item, index) => {
      //   const {
      //     Name,
      //     Title,
      //     Department,
      //     Email,
      //     LinkedIn,
      //     Phone,
      //     Tenure,
      //     Expertise,
      //     Profile,
      //     Headshot,
      //   } = item.attributes;

      //   return {
      //     Name,
      //     Title,
      //     Department,
      //     Email,
      //     LinkedIn,
      //     Phone,
      //     Tenure,
      //     Expertise,
      //     Profile,
      //     Headshot,
      //     id: item.id,
      //   };
      // });

      // setter(cleanData);
      setter(modifiedData);
      localStorage.setItem(key, JSON.stringify(modifiedData));

      return modifiedData;
    })
    .catch(function (error) {
      getLocalStorage(setter, key);
      console.log("Error fetching the data from server :: ", error.message);
    });
}
