import axios from "axios";
import { getLocalStorage, getLocalStorageEncoded } from "./getLocalStorage";

const qs = require("qs");

export async function getTestimonials(setter, key) {
  const query = qs.stringify(
    {
      populate: "deep",
    },
    // {
    //   populate: {
    //     testDZ: {
    //       populate: "*",
    //     },
    //   },
    // },
    {
      encodeValuesOnly: true,
    }
  );
  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };
  axios
    .get(`${process.env.REACT_APP_CMS_URL}/api/testimonials?${query}`, config)
    .then(res => {
      const dataTest = JSON.stringify(res.data.data);

      const modifiedData = JSON.parse(
        dataTest.replace(/\/uploads\/+/g, `${process.env.REACT_APP_CMS_URL}/uploads/`)
      );

      setter(modifiedData); //Set the state

      localStorage.setItem(key, JSON.stringify(modifiedData)); // Save it to local storage
      //   getLocalStorageEncoded(setter, key);
      return modifiedData;
    })
    .catch(function (error) {
      getLocalStorage(setter, key);
      console.log("Error fetching the data from server :: ", error.message);
    });
}
