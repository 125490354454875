import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { motion, useViewportScroll } from "framer-motion";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ButtonGroup, Button, Container, AppBar, Toolbar, Grid, useTheme } from "@mui/material";
import CustomButton from "./Button";
import { Event } from "./Tracking";
import LogoSVG from "../assets/navLogo.svg";

function MainNavigation(props) {
  const [progress, setProgress] = useState(0);
  const mounted = useRef(false);
  const { setIsModalOpen, scrollToTop, handleBookATourClick } = props;
  const theme = useTheme();

  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (mounted.current) {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
  }

  return (
    <AppBar style={{ backgroundColor: "white" }} position="fixed" elevation={0}>
      <div
        style={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <motion.div
          id="motionPath"
          style={{
            position: "relative",

            backgroundColor: theme.palette.primary.dark,
            opacity: progress + 20 + "%",
            height: 2,
            borderRight: `5px solid ${theme.palette.secondary.dark}`,
            zIndex: 999,
            width: progress + "%",
          }}
        />
      </div>
      <Toolbar role="toolbar">
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={3}>
              <Button
                component={NavLink}
                to="/"
                onClick={scrollToTop}
                style={{ borderRight: "none" }}
                title="Blume Apartments Calgary"
                tabIndex={0}
              >
                <Logo alt="Logo" src={LogoSVG} loading="lazy" />
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              {/* <ButtonWrapper variant="text" onClick={scrollToTop} fullWidth aria-label="Menu"> */}
              <Button
                component={NavLink}
                onClick={scrollToTop}
                to="/amenities"
                alt="Blume Amenities"
                aria-controls="navigation"
                tabIndex={0}
                style={{ padding: "2rem" }}
              >
                Amenities
              </Button>
              <Button
                component={NavLink}
                onClick={scrollToTop}
                to="/floorplans"
                alt="Floorplans for Blume Apartments"
                aria-controls="navigation"
                tabIndex={0}
                style={{ padding: "2rem" }}
              >
                Floorplans
              </Button>
              <Button
                component={NavLink}
                onClick={scrollToTop}
                to="/community"
                alt="Learn more about the community"
                aria-controls="navigation"
                tabIndex={0}
                style={{ padding: "2rem" }}
              >
                Community
              </Button>
              <Button
                component={NavLink}
                onClick={scrollToTop}
                to="/contact"
                alt="Contact the Blume team"
                aria-controls="navigation"
                tabIndex={0}
                style={{ padding: "2rem" }}
              >
                Contact
              </Button>
              {/* </ButtonWrapper> */}
              <CustomButton
                style={{
                  fontStyle: "normal",
                }}
                to="/#contact"
                onClick={handleBookATourClick}
                alt="Contact Us"
                aria-controls="navigation"
                tabIndex={0}
              >
                BOOK A TOUR
              </CustomButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
const ButtonWrapper = styled(ButtonGroup)`
  a {
    color: ${props => props.theme.palette.primary.main};

    border-right: none !important;

    padding: 0.5rem;
    background-color: transparent;
    text-decoration: none;

    text-align: center;
    font: normal normal normal 14px/30px Verdana;
    letter-spacing: 1.4px;
    color: #72777d;
    opacity: 1;
    &.active {
      color: ${props => props.theme.palette.secondary.dark};

      &:before {
        text-decoration: none;
      }
    }
    &:before {
      /* display: inline-block;
    position:relative */
      // content: "";
    }
  }
`;

const Logo = styled("img")`
  width: auto;
  height: 3.4375em;
  @media (min-width: 480px) {
    margin: 1em 0;
  }
`;
MainNavigation.propTypes = { scrollToTop: PropTypes.func };

export default MainNavigation;
