import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function Palette(props) {
  const [palette, setPalette] = useState("primary");
  const [swatches, setSwatches] = useState([]);
  const theme = useTheme();
  const PaletteArray = Object.keys(theme.palette);

  const handleOnPaletteChange = event => {
    setPalette(() => {
      return event.target.value;
    });
    setSwatches(() => {
      return Object.keys(theme.palette[event.target.value]);
    });
  };
  const PaletteSelector = () => {
    return (
      <FormControl mt={5} fullWidth>
        <InputLabel id="PaletteSelect">Palette</InputLabel>
        <Select
          labelId="PaletteSelect"
          id="PaletteSelect"
          value={palette}
          label="Palette"
          defaultValue={[]}
          onChange={handleOnPaletteChange}
        >
          {PaletteArray.map((key, index) => {
            if (typeof theme.palette[key] !== "object") {
              return;
            }
            return (
              <MenuItem key={key} value={key.toString()}>
                {key.toString()}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };
  return (
    <Container>
      <PaletteSelector />
      <Grid container color="white" style={{ textShadow: ".5px .5px 1px black" }}>
        {swatches.map((swatch, index) => {
          return (
            <Box
              component={Grid}
              item
              xs={2}
              m={1}
              p={1}
              bgcolor={`${palette}.${swatch}`}
              height="75px"
              key={swatch}
            >
              <Typography>{`${palette}.${swatch}`}</Typography>
              <Typography>{`${theme.palette[palette][swatch]}`}</Typography>
            </Box>
          );
        })}
      </Grid>
    </Container>
  );
}
