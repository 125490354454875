import axios from "axios";
import { getLocalStorage } from "./getLocalStorage";
const qs = require("qs");
/**
 * executes a get request and uses the supplied setter to set the state in App.js
 *
 * @export
 * @param {*} setter
 */
export async function getFloorplans(setter, key) {
  //"http://[API_ENDPOINT]:1337/api/home/?populate[Hero][populate]=%2A&populate[Intro][populate]=%2A"
  const query = qs.stringify(
    {
      populate: "deep",
    },
    {
      encodeValuesOnly: true,
    }
  );
  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };
  axios
    .get(`${process.env.REACT_APP_CMS_URL}/api/floorplan/?${query}`, config)
    .then(res => {
      const dataTest = JSON.stringify(res.data.data.attributes);

      const modifiedData = JSON.parse(
        dataTest.replace(/\/uploads\/+/g, `${process.env.REACT_APP_CMS_URL}/uploads/`)
      );
      setter(modifiedData);
      localStorage.setItem(key, JSON.stringify(modifiedData));
      return res.data.data.attributes;
    })
    .catch(function (error) {
      getLocalStorage(setter, key);
      console.log("Error fetching Floorplans data from server :: 1", error.message);
    });
}
