import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Container, useMediaQuery, useTheme, Fab } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import styled from "@emotion/styled";
import { initGA, Event } from "./components/Tracking";
import { PopupModal } from "react-calendly";
import {
  getTestimonials,
  getAmenity,
  getCommunity,
  getContact,
  getFloorplans,
  getHome,
} from "./data";
import MainNavigation from "./components/MainNavigation";
import MobileMenu from "./components/MobileMenu";

import Palette from "./Palette";

const Elevator = lazy(() => import("./containers/ElevatorBooking"));
const Home = lazy(() => import("./containers/Home"));
const Amenities = lazy(() => import("./containers/Amenities"));
const Floorplans = lazy(() => import("./containers/Floorplans"));
const Community = lazy(() => import("./containers/Community"));
const Contact = lazy(() => import("./containers/Contact"));
const Footer = lazy(() => import("./components/Footer"));
const ScrollTop = lazy(() => import("./components/ScrollTop"));
const NoMatch = lazy(() => import("./containers/NoMatch"));

const renderLoader = () => (
  <p style={{ height: "100vh", width: "100vw", textAlign: "center" }}>Loading</p>
);
function App(props) {
  const theme = useTheme();

  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [amenityData, setAmenityData] = useState({});
  const [testimonialData, setTestimonialData] = useState([{}]);
  const [communityData, setCommunityData] = useState([{}]);
  const [contactData, setContactData] = useState([{}]);
  const [floorplansData, setFloorplansData] = useState([{}]);
  const [homeData, setHomeData] = useState([{}]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => initGA("G-WB1BDZYBZ7"), []);

  useEffect(() => {
    getAmenity(setAmenityData, "blume-amenity");
    getCommunity(setCommunityData, "blume-community");
    getContact(setContactData, "blume-contact");
    getFloorplans(setFloorplansData, "blume-floorplans");
    getHome(setHomeData, "blume-home");
    getTestimonials(setTestimonialData, "blume-testimonials");
  }, []);

  const handleOnTouchStart = event => {
    setIsTouch(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleBookATourClick = () => {
    setIsModalOpen(true);
    scrollToTop();
    return true;
  };

  return (
    <Container className="App" maxWidth={"xl"} disableGutters onTouchStart={handleOnTouchStart}>
      {showTextNav && (
        <MainNavigation
          scrollToTop={scrollToTop}
          setIsModalOpen={setIsModalOpen}
          handleBookATourClick={handleBookATourClick}
          theme={theme}
        />
      )}
      {!showTextNav && (
        <MobileMenu scrollToTop={scrollToTop} handleBookATourClick={handleBookATourClick} />
      )}
      {homeData.hasOwnProperty("updatedAt") && (
        <Suspense fallback={renderLoader("app content")}>
          <Wrapper disableGutters maxWidth="xl" id="Blume">
            <Routes>
              <Route path="/" element={<Navigate replace to="home" />} />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/elevator`}
                element={<Elevator></Elevator>}
              ></Route>
              <Route
                path="home"
                element={
                  <Home theme={theme} data={homeData} handleBookATourClick={handleBookATourClick} />
                }
              />
              <Route
                path="amenities"
                element={
                  typeof amenityData.Hero === "object" && (
                    <Amenities data={amenityData} theme={theme} />
                  )
                }
              />
              <Route
                path="floorplans"
                element={
                  typeof floorplansData.Hero === "object" && (
                    <Floorplans
                      isSmall={isSmall}
                      isTouch={isTouch}
                      setIsTouch={setIsTouch}
                      data={floorplansData}
                      theme={theme}
                    />
                  )
                }
              />
              <Route
                path="community"
                element={
                  typeof communityData.Hero === "object" && (
                    <Community data={communityData} theme={theme} isSmall={isSmall} />
                  )
                }
              />
              <Route
                path="contact"
                element={
                  typeof contactData.Hero === "object" && (
                    <Contact data={contactData} theme={theme} testimonialData={testimonialData} />
                  )
                }
              />
              <Route path="palette" element={<Palette data={communityData} />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>

            {/* <TriColor /> */}
            {/* <Registration theme={theme} /> */}
            <PopupModal
              url="https://calendly.com/liveatblume/blume-tour"
              // pageSettings={this.props.pageSettings}
              // utm={this.props.utm}
              // prefill={this.props.prefill}
              onModalClose={() => setIsModalOpen(false)}
              open={isModalOpen}
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById("root")}
            />
          </Wrapper>
        </Suspense>
      )}
      {homeData.hasOwnProperty("updatedAt") && <Footer />}
      <ScrollTop {...props}>
        <Fab color={"secondary"} variant="extended" size="small" aria-label="scroll back to top">
          <ArrowUpward />
        </Fab>
      </ScrollTop>
    </Container>
  );
}
const Wrapper = styled(Container)`
  margin-top: 58px;

  ${props => props.theme.breakpoints.up("md")} {
    margin-top: 66px;
  }
`;
const List = styled.ul`
  padding-left: 1rem;
  > li {
    &::marker {
      color: ${props => props.theme.palette.primary.lightlight};
    }
  }
`;

const TriColor = styled.div`
  width: 100%;
  height: 20px;
  background: rgb(0, 140, 140);
  background: linear-gradient(
    90deg,
    rgba(0, 140, 140, 1) 0%,
    rgba(0, 140, 140, 1) 62%,
    rgba(107, 197, 167, 1) 62%,
    rgba(107, 197, 167, 1) 88%,
    rgba(211, 235, 224, 1) 88%,
    rgba(211, 235, 224, 1) 100%
  );
`;
export default App;
