export function getLocalStorage(setter, key) {
  const isLocalCorporateSet = localStorage.getItem(key);
  if (isLocalCorporateSet === null) {
    setter({});
  }
  setter(JSON.parse(localStorage.getItem(key)));
  return true;
}
export function getLocalStorageEncoded(setter, key) {
  const isLocalCorporateSet = localStorage.getItem(key);
  if (isLocalCorporateSet === null) {
    setter({});
  }
  setter(atob(JSON.parse(isLocalCorporateSet)));
  return true;
}
